import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { take } from 'rxjs/operators';
import { NotificationService } from '@ess-front/brain-shared';
import { UserService } from '@ess-front/shared';
import { AuthService } from '@ess-front/authorization';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private readonly injector: Injector,
    private readonly ngZone: NgZone,
    private userService: UserService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(err: Error | HttpErrorResponse | any): void {
    // eslint-disable-next-line no-console
    console.error(err);
    const router = this.injector.get(Router);
    const notificationService = this.injector.get(NotificationService);
    const authService = this.injector.get(AuthService);
    const error = err.rejection ? err.rejection : err;

    if (
      !this.isLocal() &&
      !window.location.href.includes('/login') &&
      (!(error instanceof HttpErrorResponse) ||
        (error instanceof HttpErrorResponse && error?.status !== 400 && error?.status !== 401 && error?.status !== 403))
    ) {
      this.userService
        .getUser$()
        .pipe(take(1))
        .subscribe(user => {
          if (error instanceof Error) {
            Sentry.captureException(error, { user } as unknown);
          } else {
            Sentry.captureException(error.originalError || error.error || error.message || error, { user } as unknown);
          }
        });
    }

    this.ngZone.run(() => {
      if (error instanceof HttpErrorResponse) {
        let message = 'Sorry, there was an error on the server.';
        switch (error.status) {
          case 400:
            message = this.getFormError(error);
            break;
          case 401:
            if (authService.isAuthenticated()) {
              message = 'User not authenticated, redirecting to login';
              authService.logout();
            }
            break;
          case 403:
            message = 'User not authorized';
            break;
          case 404:
            message = 'The requested URL was not found';
            router.navigate(['/not-found']);
            break;
          default:
        }
        notificationService.notifyWithComponent(
          null,
          {
            panelClass: 'error',
          },
          null,
          message,
        );
      }
    });
  }

  getFormError(e: HttpErrorResponse): string {
    /* eslint-disable no-underscore-dangle */
    let message = 'Sorry, something went wrong saving the form';
    const error = e.error;
    if (error) {
      // "__all__ or non_field_errors" error means something went wrong in the form with no specific field
      if (error.__all__ || error.non_field_errors || Array.isArray(error)) {
        // If this error is caught, it does not process any more
        const errors_list = error.__all__ || error.non_field_errors || error;
        message = `${errors_list.join('. ')}.`;
      } else if (error.detail) {
        // If server response with a generic error (4XX) with a custom message.
        message = error.detail;
      } else {
        message = this.formatErrorHelper(error, Object.keys(error).pop()); // Just takes the first key (random), ignore the rest
      }
    }
    return message;
    /* eslint-enable no-underscore-dangle */
  }

  private formatErrorHelper(error: unknown, keyError: string, index?: number): string {
    let message = '';

    if (error[keyError] instanceof Array) {
      message += index ? `<span>${keyError} ${index}: </span>` : `<span>${keyError}: </span>`;
      error[keyError].forEach((errorDetail, i) => {
        if (typeof errorDetail === 'string') {
          message += ` ${errorDetail}`;
        } else if (Object.keys(errorDetail).length > 0) {
          message += `<p>${this.formatErrorHelper(errorDetail, Object.keys(errorDetail).pop(), i + 1)}</p>`; // Just takes the first key (random), ignore the rest
        }
      });
    }
    return message;
  }

  private isLocal(): boolean {
    return (
      window.location.href.includes('localhost') ||
      window.location.href.includes('0.0.0.0') ||
      window.location.href.includes('127.0.0.1')
    );
  }
}
