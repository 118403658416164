<mat-sidenav-container fullscreen>
  <!-- SIDE MENU -->
  <mat-sidenav #SideNavStart class="sidenav_right" mode="side" position="start">
    <mat-nav-list class="menu">
      <a (click)="SideNavStart.close()" [routerLink]="['/countries']" class="menu_item" mat-list-item>
        <mat-icon matListItemIcon> place </mat-icon>
        <h4 matListItemLine>Destinations</h4>
      </a>

      <a (click)="SideNavStart.close()" [routerLink]="['/billing']" class="menu_item" mat-list-item>
        <mat-icon matListItemIcon> receipt </mat-icon>
        <h4 matListItemLine>Billing</h4>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- TOP MENU -->
    <mat-toolbar fxLayoutAlign="start stretch" fxLayoutGap=" 1.25rem">
      <div class="side_menu_button" fxFlex="0 0 2.5rem" fxLayoutAlign="center center">
        <button (click)="SideNavStart.open()" *ngIf="!SideNavStart.opened" color="primary" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>

        <button (click)="SideNavStart.close()" *ngIf="SideNavStart.opened" color="primary" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div fxFlex="1 1 auto" fxLayoutAlign="center center">
        <div class="toolbar-user" fxFlex="0 1 87.5rem" fxLayoutAlign="center center">
          <div fxLayoutAlign="center center">
            <img alt="logo" class="logo" src="assets/logos/black-logo-transparent-background.png" />
          </div>

          <div fxFlex="1 1 auto"></div>

          <ess-user-menu fxFlex="0 1 9.375rem" fxFlexAlign="end" />
        </div>
      </div>
    </mat-toolbar>

    <main>
      <div class="main_container">
        <router-outlet />
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
